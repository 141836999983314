import "@hotwired/turbo-rails"
import "../turbo-mount"
import "../controllers"

import "trix"
import "@rails/actiontext"

import "../config"
import "../channels"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

Turbo.FrameElement.delegateConstructor.prototype.proposeVisitIfNavigatedWithAction = function(frame, action = null) {
  this.action = action

  if (this.action) {
    // const pageSnapshot = PageSnapshot.fromElement(frame).clone()
    const pageSnapshot = Turbo.PageSnapshot.fromElement(frame).clone()
    const { visitCachedSnapshot } = frame.delegate

    // frame.delegate.fetchResponseLoaded = async (fetchResponse) => {
    frame.delegate.fetchResponseLoaded = (fetchResponse) => {
      if (frame.src) {
        const { statusCode, redirected } = fetchResponse
        // const responseHTML = await fetchResponse.responseHTML
        const responseHTML = frame.ownerDocument.documentElement.outerHTML
        const response = { statusCode, redirected, responseHTML }
        const options = {
          response,
          visitCachedSnapshot,
          willRender: false,
          updateHistory: false,
          restorationIdentifier: this.restorationIdentifier,
          snapshot: pageSnapshot
        }

        if (this.action) options.action = this.action

        // session.visit(frame.src, options)
        Turbo.session.visit(frame.src, options)
      }
    }
  }
}

import Dropdown from 'bootstrap/js/dist/dropdown'
// import Accordion from 'bootstrap/js/dist/ac'
import Collapse from 'bootstrap/js/dist/collapse'

document.addEventListener('turbo:frame-missing', function (event) {
  event.preventDefault()
  event.detail.visit(event.detail.response)
})